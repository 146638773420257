import React from "react"
import styled, { css } from "styled-components"
import PropTypes from "prop-types"
import { BgImage, Image } from "../../components/images.component"
import { condition } from "../structure/theme"
import breakpoint from "styled-components-breakpoint"
import Kpi from "./home/kpi.component"
import RowsWithAdaptativeImages from "../../components/banner/rowsWithAdaptativeImages.component"
import { BANNER_TEMPLATES } from "./banner.utils"
import Section from "../../components/section.component"

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: ${props => props.backgroundColor};

  ${breakpoint(`medium`)`
    margin-bottom: ${condition(`bevel`, `60px`)};
  `}

  ${breakpoint(`small`)`
    ${props =>
      props.template === BANNER_TEMPLATES.CENTER &&
      css`
        background-color: white;
      `}
  `}
`

const Container = styled.div`
  background-color: ${props => props.backgroundColor};
  min-height: ${props => (props.template === BANNER_TEMPLATES.NO_IMG ? 0 : `370px`)};
  position: relative;
  display: flex;
  padding: ${props => `0 0 ${Number(props.kpi) * 40}px 0`};

  ${props =>
    props.template === BANNER_TEMPLATES.RIGHT &&
    css`
      padding: unset;
      padding: 40px 0 40px 0;
      min-height: 270px;
    `}

  ${breakpoint(`small`)`
    padding-top: ${props => (props.template === BANNER_TEMPLATES.RIGHT && props.kpi ? 50 : 0)}px;
    padding-bottom: ${props => (props.kpi ? (props.template === BANNER_TEMPLATES.RIGHT ? 80 : 30) : 0)}px;

    ${props =>
      props.template === BANNER_TEMPLATES.RIGHT &&
      css`
        padding: 28px 20px 55px 20px;
      `}
  `}
`

const Half = styled.div`
  flex: 1;
  flex-direction: column;
  align-items: ${props => (props.template === BANNER_TEMPLATES.CENTER ? `center` : `flex-start`)};
  justify-content: center;
  display: flex;
  position: relative;
`

const SubContainer = styled.div`
  margin: auto;
  width: 100%;
  max-width: ${props => props.theme.maxWidth.medium}px;
`

const Title = styled.h1`
  font-weight: 300;
  font-size: ${props => props.theme.fonts.sizes.xx_large};
  line-height: 40px;
  color: ${props => props.titleColor};
  text-align: left;

  ${props =>
    (props.template === BANNER_TEMPLATES.CENTER || props.template === BANNER_TEMPLATES.NO_IMG) &&
    `
    margin: 32px auto 32px;
    text-align: center;
    max-width: 80%;
  `}

  ${props =>
    props.template === BANNER_TEMPLATES.RIGHT &&
    `
    margin-top: 0;
  `}

  b {
    font-weight: 900;
  }

  p {
    margin: 0;
  }

  ${breakpoint(`medium`)`
    font-size: ${props => props.theme.fonts.sizes.xxx_large};
    line-height: 50px;
    max-width: ${props => (props.template === BANNER_TEMPLATES.CENTER ? 60 : 100)}%;
    ${props =>
      props.template === BANNER_TEMPLATES.RIGHT &&
      `
      margin-top: 0;
    `}
  `}
`

const ImageContainer = styled.div`
  display: ${condition(`hideImageForResponsive`, `none`, `block`)};
  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: ${condition(`bevel`, `rgba(0, 0, 0, 0.4)`, null)};
    transform: ${condition(`bevel`, `skewY(-2deg) translateY(-10px)`, `none`)};
  }

  img {
    background-color: white;
    transform: ${condition(`bevel`, `skewY(-2deg) translateY(-10px)`, `none`)};
  }

  ${breakpoint(`small`)`
    display: block;
      &:after {
        transform: ${condition(`bevel`, `skewY(-1deg) translateY(-10px)`, `none`)};
      }

    img {
      transform: ${condition(`bevel`, `skewY(-1deg) translateY(-10px)`, `none`)};
    }
  `}

  ${breakpoint(`medium`)`
    img {
      transform: none;
    }
    &:after {
        background: rgba(0, 0, 0, 0.0);
      }
  `}
`

const ImageRight = styled(Image)`
  display: none;

  ${breakpoint(`small`)`
    display: block;
  `}
`

export default function Banner ({
  img = null,
  imgMinHeight = 180,
  kpi = true,
  bannerBgColor = `white`,
  titleColor = APP_CONFIG.theme.colors.secondary,
  hideImageForResponsive = false,
  template = BANNER_TEMPLATES.CENTER,
  children: originalChildren,
  bevel = false,
}) {
  const children = React.Children.toArray(originalChildren)
  const displayBackgroundImage = img && template !== BANNER_TEMPLATES.RIGHT

  return (
    <Section data-testid={`banner_${template}`} bgWidth="large" contentWidth="large" bgFullWidthMobile>
      <Wrapper template={template} backgroundColor={bannerBgColor} bevel={bevel}>
        {displayBackgroundImage && (
          <ImageContainer
            bevel={bevel}
            data-testid="banner_img_background"
            hideImageForResponsive={hideImageForResponsive}
          >
            <BgImage
              file={img}
              alt="Bannière"
              objectPosition={`${template === BANNER_TEMPLATES.LEFT ? `right` : `50%`} 50%`}
            />
          </ImageContainer>
        )}
        <Container kpi={kpi} template={template}>
          <SubContainer>
            <RowsWithAdaptativeImages
              firstRowSize={template === BANNER_TEMPLATES.RIGHT ? 2 : 1}
              styleRow={{ padding: `0 15px` }}
              imageProps={{ style: { minHeight: `${imgMinHeight}px` } }}
              componentImage={template === BANNER_TEMPLATES.RIGHT ? ImageRight : Image}
              template={template}
            >
              <Half template={template}>
                {children.map((child, i) => {
                  if (i === 0 && !React.isValidElement(child)) {
                    return (
                      <Title
                        template={template}
                        key={i}
                        data-testid="banner_title"
                        titleColor={titleColor}
                        dangerouslySetInnerHTML={{
                          __html: child,
                        }}
                      />
                    )
                  }

                  return <React.Fragment key={i}>{child}</React.Fragment>
                })}
              </Half>
              {img && template === BANNER_TEMPLATES.RIGHT && <ImageRight file={img} />}
            </RowsWithAdaptativeImages>
          </SubContainer>
        </Container>

        {kpi && bevel && <Kpi bevel={bevel} />}
      </Wrapper>
      {kpi && !bevel && <Kpi />}
    </Section>
  )
}

Banner.propTypes = {
  img: PropTypes.object,
  imgMinHeight: PropTypes.number,
  kpi: PropTypes.bool,
  titleColor: PropTypes.string,
  bannerBgColor: PropTypes.string,
  template: PropTypes.oneOf(Object.values(BANNER_TEMPLATES)),
  children: PropTypes.node,
  hideImageForResponsive: PropTypes.bool,
  bevel: PropTypes.bool,
}
