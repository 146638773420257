import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { fontSizes } from "../structure/theme"
import breakpoint from "styled-components-breakpoint"
import Wysiwyg from "../../components/wysiwyg.component"
import { Secondary } from "../structure/theme/titles.component"
import Link from "../navigation/link.component"
import { Bold, Emphasis, Underlined, Strikethrough } from "../structure/theme/styles.component"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const VideoContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
  justify-content: center; /* => space-between pour la vidéo */
  align-items: center;
  width: 100%;

  ${breakpoint(`medium`)`
    flex-direction: row;
  `}
`

// const Video = styled.video`
//   width: 100%;
//   max-width: 450px;
//   height: auto;
//   background-color: red;
//   margin-top: 20px;

//   ${breakpoint(`medium`)`
//         margin-top: 0;
//     `}

//   ${breakpoint(`small`)`
//         min-width: 540px;
//         width: 540px;
//         height: 305px;
//     `}
// `

const Title = styled(Secondary)`
  font-weight: 900;
  line-height: 28px;
  margin-top: 50px;
  font-size: ${fontSizes(`x_large`)};
  color: ${props => props.theme.colors.primary};
`

const Teaser = styled.p`
  line-height: 32px;
  font-size: ${fontSizes(`large`)};
  max-width: 920px;
  margin: auto;

  ${breakpoint(`medium`)`
    // text-align: left; => décomenter pour la vidéo
  `}
`

const Colored = styled.span`
  color: ${props => props.theme.colors.primary};
`

const Text = styled.p`
  line-height: 28px;
  font-size: ${fontSizes(`regular`)};
  max-width: 920px;
  margin: 0;
`

const replace = (type, attr, isTeaser) => {
  switch (type) {
    case `h1`:
    case `h2`:
    case `h3`:
    case `h4`:
    case `h5`:
    case `h6`:
      return {
        component: Title,
        attributes: {
          as: `h2`,
        },
      }
    case `a`:
      return {
        component: Link,
        attributes: {
          href: attr.href,
        },
      }
    case `strong`:
      return {
        component: Bold,
      }
    case `span`:
      return {
        component: Colored,
      }
    case `em`:
      return {
        component: Emphasis,
      }
    case `u`:
      return {
        component: Underlined,
      }
    case `s`:
      return {
        component: Strikethrough,
      }
    case `p`:
      return {
        component: isTeaser ? Teaser : Text,
      }
  }
}

function getJSON (string) {
  return JSON.parse(string || `{"type": ""}`)
}

export default function VideoWithDetails ({ teaser, description }) {
  return (
    <Container>
      <VideoContainer>
        {/* <Video data-testid="video_description_video" /> */}
        <Wysiwyg
          json={getJSON(teaser)}
          replace={(type, attr) => replace(type, attr, true)}
          data-testid="category_description_teaser"
        />
      </VideoContainer>
      <Wysiwyg
        json={getJSON(description)}
        replace={(type, attr) => replace(type, attr)}
        data-testid="category_description_paragraphe"
      />
    </Container>
  )
}

VideoWithDetails.propTypes = {
  teaser: PropTypes.string,
  description: PropTypes.string,
}
