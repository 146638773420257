import React from "react"
import PropTypes from "prop-types"
import Picto from "../picto.component"

export default function Check (props) {
  return (
    <Picto
      viewbox="0 0 165 128"
      d={[
        `M67.1,125.8c-2.9,0-5.7-1.1-7.8-3.1L6.4,73.9c-4.7-4.3-5-11.6-0.6-16.3c4.3-4.7,11.6-5,16.3-0.6L66,97.7   l76-91.3c4.1-4.9,11.3-5.6,16.2-1.5c4.9,4.1,5.6,11.3,1.5,16.2L75.9,121.7c-2,2.4-4.9,3.9-8.1,4.1   C67.6,125.8,67.3,125.8,67.1,125.8z`,
      ]}
      {...props}
    />
  )
}

Check.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}
