import React, { useState } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { colors, fontSizes, useThemeContext } from "../structure/theme"
import { HOUSINGTYPE_APPARTEMENT, HOUSINGTYPE_MAISON } from "../product/product.utils"
import { Button } from "../structure/theme/button.component"
import breakpoint from "styled-components-breakpoint"
import FilterCheckbox from "../../components/filterCheckbox.component"
import { useTranslation } from "../translation/translate.component"

const Container = styled.div`
  max-width: 940px;
  border-radius: 3px;
  padding: 20px 5px 0px;

  ${breakpoint(`medium`)`
    margin: 70px auto;
    padding: 40px 100px 60px;
    background-color: rgba(255, 255, 255, 0.93);
    box-shadow: 9.5px 3.3px 35px 0 rgba(0, 0, 0, 0.15);
  `}
`

const Title = styled.h1`
  font-weight: 900;
  line-height: 1.25;
  font-size: ${fontSizes(`xx_large`)};
  margin: 0;
  text-align: center;
  color: white;

  ${breakpoint(`medium`)`
    color: ${colors(`primary`)};
  `}

  > p {
    margin: 0;
  }
`

const Accroche = styled.div`
  line-height: 1.25;
  text-align: center;
  font-size: ${fontSizes(`x_large`)};
  color: white;
  margin-top: 20px;

  ${breakpoint(`medium`)`
    margin-top: 0;
    color: black;
    font-size: ${fontSizes(`xx_large`)};
  `}
`

const Filter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  border-radius: 3px;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.21);
  border: solid 1px ${colors(`grey.g400`)};
  margin-top: 30px;
  background-color: rgba(255, 255, 255, 1);
  max-width: 100%;
  padding: 20px 24px;

  ${breakpoint(`small`)`
    flex-wrap: unset;
    flex-direction: row;
  `}
`

const ButtonContainer = styled.div`
  margin-top: 25px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${breakpoint(`small`)`
    width: 250px;
    margin-top: 0;
    margin-left: 10px;
  `}
`

const ClickWrapper = styled.div`
  display: flex;
  cursor: pointer;
`

const SearchButton = styled(Button).attrs({
  major: true,
})`
  text-transform: none;
  margin: auto;
  max-width: 250px;
  width: 100%;
`

const Label = styled.span`
  color: ${colors(`primary`)};
  font-weight: 900;
  text-transform: capitalize;
`

const Separator = styled.span`
  :after {
    content: "/";
    color: ${colors(`primary`)};
    margin: 0 10px;
  }
`

const Checkbox = styled(FilterCheckbox)`
  border-color: ${colors(`grey.g650`)};
`

export default function SearchBanner ({ title, hook, onSearch, initialCheck = HOUSINGTYPE_MAISON }) {
  const { t } = useTranslation()
  const [housingType, setHousingType] = useState(initialCheck)
  const {
    modules: {
      category: { bannerCheckColor },
    },
  } = useThemeContext()

  return (
    <Container>
      <Title
        data-testid="category_banner_title"
        dangerouslySetInnerHTML={{
          __html: title,
        }}
      />
      <Accroche
        data-testid="category_banner_description"
        dangerouslySetInnerHTML={{
          __html: hook,
        }}
      />
      <Filter data-testid="category_banner_filter">
        <ClickWrapper data-testid="category_banner_filter_maison" onClick={() => setHousingType(HOUSINGTYPE_MAISON)}>
          <Checkbox checked={housingType === HOUSINGTYPE_MAISON} checkColor={bannerCheckColor} />
          <Label>{t(`common:generic.housing_type_house`)}</Label>
        </ClickWrapper>
        <Separator />
        <ClickWrapper
          data-testid="category_banner_filter_appartement"
          onClick={() => setHousingType(HOUSINGTYPE_APPARTEMENT)}
        >
          <Checkbox
            checked={housingType === HOUSINGTYPE_APPARTEMENT}
            onClick={() => setHousingType(HOUSINGTYPE_APPARTEMENT)}
            checkColor={bannerCheckColor}
          />
          <Label>{t(`common:generic.housing_type_flat`)}</Label>
        </ClickWrapper>

        <ButtonContainer>
          <SearchButton data-testid="category_banner_search" onClick={() => onSearch(housingType)}>
            {t(`common:category.banner_search_button`)}
          </SearchButton>
        </ButtonContainer>
      </Filter>
    </Container>
  )
}

SearchBanner.propTypes = {
  title: PropTypes.string.isRequired,
  hook: PropTypes.string.isRequired,
  onSearch: PropTypes.func.isRequired,
  initialCheck: PropTypes.string,
}
