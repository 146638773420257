import React from "react"
import PropTypes from "prop-types"

export default function FilterCheck ({ checkColor = APP_CONFIG.theme.colors.primary, ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32.533" height="25.283" viewBox="0 0 32.533 25.283" {...props}>
      <g transform="translate(-7.08 -13.587)">
        <g transform="translate(8.5 15)">
          <g fill={checkColor} transform="translate(-8.5 -15)">
            <path
              stroke="none"
              d="M 18.97550964355469 38.16323089599609 L 18.6219596862793 37.80966949462891 L 8.146450042724609 27.33415985107422 L 7.789859771728516 26.97756958007812 L 8.149479866027832 26.62405014038086 L 11.70151996612549 23.1322193145752 L 12.05504989624023 22.7846794128418 L 12.40559005737305 23.13522911071777 L 18.97619819641113 29.7058277130127 L 34.09510040283203 14.64575958251953 L 34.44865036010742 14.29358005523682 L 34.80150985717773 14.64645004272461 L 38.35354995727539 18.19849014282227 L 38.80636978149414 18.65130043029785 L 38.25725936889648 18.98077964782715 L 38.00899505615234 19.12974548339844 L 19.32905960083008 37.80966949462891 L 18.97550964355469 38.16323089599609 Z"
            />
            <path
              stroke="none"
              fill="#fff"
              d="M 34.44795989990234 15 L 18.97550964355469 30.41223907470703 L 12.05204010009766 23.48877906799316 L 8.5 26.98060989379883 L 18.97550964355469 37.45611953735352 L 37.69898223876953 18.73265075683594 L 38 18.55204010009766 L 34.44795989990234 15 M 34.4493408203125 13.587158203125 L 35.15507125854492 14.29288864135742 L 38.70711135864258 17.84492874145508 L 39.61275863647461 18.75057983398438 L 38.51448822021484 19.40953826904297 L 38.31904220581055 19.52680587768555 L 19.68261909484863 38.16323089599609 L 18.97550964355469 38.87033081054688 L 18.26840019226074 38.16323089599609 L 7.792888641357422 27.68771934509277 L 7.079719543457031 26.97454071044922 L 7.798961639404297 26.26749038696289 L 11.35100173950195 22.77565956115723 L 12.05805969238281 22.08058929443359 L 12.75915145874023 22.78166961669922 L 18.97689056396484 28.9994010925293 L 33.74222946166992 14.29151916503906 L 34.4493408203125 13.587158203125 Z"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

FilterCheck.propTypes = {
  checkColor: PropTypes.string,
}
