import React, { useMemo } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import SeoSchema from "../modules/seo/seoSchema.component"
import { toPx } from "../modules/structure/theme"

const Container = styled.div`
  position: relative;
  max-width: 100%;
  width: ${props => toPx(props._width)};
  ${props =>
    (props._height === `auto` &&
      `
      height: 0;
      padding-bottom: 56.25%;
    `) ||
    `
      height: ${toPx(props._height)};
    `}
`

const Iframe = styled.iframe`
  display: block;
  margin: 0;
  padding: 0;
  max-width: 100%;
  width: ${props => toPx(props._width)};

  ${props =>
    (props._height === `auto` &&
      `
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
    `) ||
    `
      height: ${toPx(props._height)};
    `}
`

export default function Youtube ({
  videoId,
  width = `100%`,
  height = `auto`,
  videoName = ``,
  description = ``,
  ...attributes
}) {
  const src = APP_CONFIG.youtubeEmbed + videoId

  const seoProps = useMemo(
    () => ({
      videoName,
      description,
      embedUrl: src,
      thumbnailUrl: `http://i3.ytimg.com/vi/${videoId}/maxresdefault.jpg`,
    }),
    [videoId],
  )

  return (
    <Container _height={height} _width={width}>
      <SeoSchema name="videoObject" {...seoProps} />
      <Iframe type="text/html" _width={width} _height={height} {...attributes} frameBorder="0" src={src} />
    </Container>
  )
}

Youtube.propTypes = {
  videoId: PropTypes.string.isRequired,
  videoName: PropTypes.string,
  description: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
}
