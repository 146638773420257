import React, { useMemo } from "react"
import styled from "styled-components"
import { condition, percent, useThemeContext } from "../../structure/theme"
import breakpoint from "styled-components-breakpoint"
import Check from "../../../components/pictos/check.component"
import { Bold } from "../../structure/theme/styles.component"
import PropTypes from "prop-types"
import { usePageContext } from "../../navigation/pageContext.provider"
import { useCoBrand } from "../../coBrand/coBrand.provider"
import { Trans, useTranslation } from "../../translation/translate.component"

const KpiContainer = styled.div`
  z-index: ${condition(`bevel`, 1, 0)};

  background-color: ${props => {
    if (props.bevel) {
      return `transparent`
    }
    if (props.homepageTheme) {
      return props.theme.colors.primary
    }

    return props.backgroundColor
  }};
  margin: 0 auto;
  padding: 14px;
  transform: translateY(-25%);
  width: 100%;

  ${props =>
    props.homepageTheme &&
    `
      transform: none;
      
      ${PictoContainer} {
        display: none;
      }
  `}

  ${breakpoint(`small`)`
    border-radius: 5px;
    max-width: 500px;
    text-align: center;
    transform: translateY(-25%);

     ${props =>
       props.homepageTheme &&
       `
        background-color: ${props.theme.colors.grey.g800};
        
        ${PictoContainer} {
          display: inline-block;
        }
    `}
  `}

  ${breakpoint(`medium`)`
    display: flex;
    flex-wrap: wrap;
    max-width: 1140px;
    transform: translateY(-50%);
    ${props =>
      !props.homepageTheme &&
      `
      background-color: ${props.backgroundColor};
    `}
    
  ${props =>
    props.bevel &&
    `
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  `}
  `}
`

const PictoContainer = styled.span`
  margin-right: 5px;
`

const Container = styled.div`
  text-align: center;

  ${breakpoint(`medium`)`
    display: flex;
    justify-content: center;
  `}
`

const KpiItemWrapper = styled.div`
  width: 100%;
  color: ${props => props.textColor};

  ${breakpoint(`medium`)`
    width: ${percent(1 / 3)};
  `};
`

function KpiItem ({ children, campaignTheme }) {
  const {
    colors: { grey },
  } = useThemeContext()
  const color = campaignTheme ? grey.g800 : `white`

  return (
    <KpiItemWrapper textColor={color}>
      <Container>
        <PictoContainer>
          <Check size={20} color={color} />
        </PictoContainer>
        <span>{children}</span>
      </Container>
    </KpiItemWrapper>
  )
}

KpiItem.propTypes = {
  children: PropTypes.node.isRequired,
  campaignTheme: PropTypes.bool.isRequired,
}

export default function Kpi ({ bevel = false }) {
  const { t } = useTranslation()
  const {
    modules: {
      campaign: { kpiColor },
    },
    colors,
  } = useThemeContext()
  const { coBrand } = useCoBrand()
  const { route } = usePageContext()
  const homepageTheme = route === `home`
  const campaignTheme = route === `campaign` || route === `coBrandLps`

  const backgroundColor = useMemo(() => {
    if (route === `coBrandLpm` && coBrand && coBrand.headerBorderBottomColor) {
      return coBrand.headerBorderBottomColor
    }
    switch (route) {
      case `home`: {
        return colors.primary
      }
      case `campaign`:
      case `coBrandLps`: {
        return kpiColor
      }
      case `category`: {
        return `black`
      }
      default: {
        return colors.accent
      }
    }
  }, [route, coBrand, kpiColor, colors])

  return (
    <KpiContainer data-testid="kpi" homepageTheme={homepageTheme} backgroundColor={backgroundColor} bevel={bevel}>
      <KpiItem campaignTheme={campaignTheme}>
        <Trans
          t={t}
          i18nKey={
            process.env.PARTNER === `hsvbe`
              ? `common:landing.banner_kpi_assistance`
              : `common:landing.banner_kpi_professional`
          }
          components={{ strong: <Bold /> }}
        />
      </KpiItem>
      <KpiItem campaignTheme={campaignTheme}>
        <Trans t={t} i18nKey="common:landing.banner_kpi_intervention" components={{ strong: <Bold /> }} />
      </KpiItem>
      <KpiItem campaignTheme={campaignTheme}>
        <Trans
          t={t}
          i18nKey={
            process.env.PARTNER === `hsvbe` ? `common:landing.banner_kpi_local` : `common:landing.banner_kpi_advices`
          }
          components={{ strong: <Bold /> }}
        />
      </KpiItem>
    </KpiContainer>
  )
}

Kpi.propTypes = {
  bevel: PropTypes.bool,
}
