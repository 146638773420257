import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { colors } from "../modules/structure/theme"
import breakpoint from "styled-components-breakpoint"
import FilterCheck from "./pictos/filterCheck.component"

const Wrapper = styled.span`
  position: relative;
`

const Box = styled.span`
  width: 20px;
  display: inline-block;
  height: 20px;
  border-radius: 2px;
  border: solid 1px ${colors(`primary`)};
  position: relative;
  margin-right: 20px;

  ${breakpoint(`medium`)`
    margin-right: 30px;
  `};
`

const Check = styled(FilterCheck)`
  position: absolute;
  pointer-events: none;
  top: -6px;
  left: 2px;
`

function FilterCheckbox ({ checked, checkColor, ...styledProps }) {
  return (
    <Wrapper>
      <Box {...styledProps} />
      {checked && <Check checkColor={checkColor} />}
    </Wrapper>
  )
}

FilterCheckbox.propTypes = {
  checked: PropTypes.bool.isRequired,
  checkColor: PropTypes.string,
}

export default FilterCheckbox
