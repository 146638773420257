import React, { useMemo } from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import SeoSchema from "../seo/seoSchema.component"
import { tokenizeObject } from "../../utils/format"
import { useEventCampaignProductsTracking, useViewPageTracking, addEventTracking } from "../thirdParties/gtm/tagManager"
import Banner from "../landing/banner.component"
import SearchBanner from "../category/searchBanner.component"
import VideoWithDetails from "./videoWithDetails.component"
import { useSortProductsByPrice } from "../product/product.utils"
import { encodeQueryParams } from "use-query-params"
import { resultPageQueryParams } from "../search/search.utils"
import { useTranslation } from "../translation/translate.component"
import useNavigation from "../navigation/useNavigation"
import DynamicBlock from "../../components/block/dynamicBlock.component"
import Block from "../../components/block/block.component"

export default function CategoryPage ({ data: { categoryPage, allInterventionExample } }) {
  const { t } = useTranslation()
  const { navigate, resolveRoute } = useNavigation()
  const {
    title,
    slug,
    categoryPageProducts,
    universes,
    bannerTitle,
    bannerText,
    bannerImg,
    teaser,
    description,
  } = categoryPage

  const seoProducts = useMemo(
    () =>
      categoryPageProducts.map(product => {
        const { name: productName, visual, impactProduct } = product
        const { standardPrice, offerPrice } = impactProduct.variants[0]

        return tokenizeObject(APP_CONFIG.seo.schema.productItem, {
          productName,
          imageUrl: visual.file.publicURL,
          productUrl: resolveRoute(`product`, product),
          price: Math.min(standardPrice, offerPrice),
        })
      }),
    [categoryPageProducts],
  )

  const products = useSortProductsByPrice(categoryPageProducts)

  useViewPageTracking(title, ``, `Page Gamme`)
  useEventCampaignProductsTracking(`Page Gamme - ${title}`, products)

  const onSearch = type => {
    addEventTracking(`eventGA`, {
      eventCategory: `Recherche_Gamme`,
      eventAction: `Lancement`,
      eventLabel: type,
    })

    navigate(
      `results`,
      null,
      encodeQueryParams(resultPageQueryParams, {
        h: type,
        u: universes.map(universe => universe.slug),
      }),
    )
  }

  return (
    <>
      <SeoSchema name="universe" url={slug} number={seoProducts.length} products={seoProducts} />
      <Banner img={bannerImg} bevel>
        <SearchBanner title={bannerTitle} hook={bannerText} onSearch={onSearch} />
      </Banner>
      <DynamicBlock
        __component="InterventionExample"
        data-testid="intervention_example_block"
        blockTitle={t(`common:navigation.block_exemple_title`)}
        blockTitleSeo="h2"
        interventionExamples={allInterventionExample.nodes}
      />
      <DynamicBlock
        __component="ThreeSteps"
        data-testid="three_steps"
        blockTitle={t(`common:information.section_three_steps_title`)}
      />
      <DynamicBlock
        __component="ProductBlock"
        data-testid="product_block_appartement"
        blockTitle={t(`common:category.section_products_title`, {
          page_title: title.toLowerCase(),
        })}
        blockTitleSeo="h2"
        blockBgWidth="large"
        blockAnchor="appartement"
        blockBgColor="#F8F7F5"
        products={products}
        withPrice
        withUniverseDescription
        withLogo
      />

      <Block>
        <VideoWithDetails teaser={teaser} description={description} />
      </Block>

      <DynamicBlock
        __component="Warranties"
        data-testid="warranties_block"
        blockTitle={t(`common:category.section_warranties_title`)}
        blockTitleSeo="h2"
      />
    </>
  )
}

CategoryPage.propTypes = {
  data: PropTypes.shape({
    categoryPage: PropTypes.shape({
      slug: PropTypes.string.isRequired,
      title: PropTypes.string,
      universes: PropTypes.arrayOf(PropTypes.object).isRequired,
      categoryPageProducts: PropTypes.arrayOf(PropTypes.object).isRequired,
      bannerTitle: PropTypes.string,
      bannerText: PropTypes.string,
      bannerImg: PropTypes.object,
      teaser: PropTypes.string,
      description: PropTypes.string,
    }).isRequired,
    allInterventionExample: PropTypes.shape({
      nodes: PropTypes.array.isRequired,
    }).isRequired,
  }).isRequired,
}

export const pageQuery = graphql`
  query CategoryPage($id: String!) {
    categoryPage(id: { eq: $id }) {
      title
      slug
      universes {
        slug
        pictoSvg
      }
      categoryPageProducts {
        ...productByUniverse
      }
      bannerImg {
        file {
          ...responsiveFileImage
        }
      }
      bannerText
      bannerTitle
      teaser
      description
    }

    allInterventionExample(
      filter: { page: { id: { eq: $id } }, description: { ne: "" }, link: { ne: "" } }
      sort: { fields: position, order: ASC }
    ) {
      nodes {
        title
        description
        link
        linkText
      }
    }
  }
`
