import React from "react"
import PropTypes from "prop-types"
import YouTube from "./youtube.component"
import { Image } from "./images.component"
import _get from "lodash/get"
import _noop from "lodash/noop"

function parseColor (css) {
  let attr = {}
  const valueOfColor = css.match(/(!?(;| |^))color: (.*?);/)

  if (valueOfColor) {
    attr = { color: valueOfColor[valueOfColor.length - 1] }
  }

  return attr
}

function getType (type, attributes, fields, replace = _noop) {
  let resultType = type
  const resultAttributes = {}
  switch (type) {
    case `script`:
    case `style`: {
      return {
        type: null,
      }
    }
    case `parsererror`: {
      if (!process.env.OPTIMIZED) {
        return {
          type: `div`,
          attributes: {
            style: {
              color: `red`,
            },
          },
        }
      }

      return { type: null }
    }
    case `iframe`: {
      if (attributes.src && attributes.src.includes(`youtube`)) {
        resultType = YouTube
        resultAttributes.videoId = attributes.src.split(`/`).pop()
      }
      break
    }
    case `img`: {
      if (!(`field` in attributes)) {
        return { type: null }
      }
      const file = _get(fields, attributes.field).find(item => item.id === attributes.id)
      if (!file) {
        return { type: null }
      }
      resultType = Image
      resultAttributes.file = _get(fields, attributes.field).find(item => item.id === attributes.id)
    }
  }

  for (const attributeKey in attributes) {
    if (attributeKey in resultAttributes) {
      continue
    }
    switch (attributeKey) {
      case `frameborder`: {
        resultAttributes.frameBorder = attributes[attributeKey]
        break
      }
      case `allowfullscreen`: {
        resultAttributes.allowFullScreen = attributes[attributeKey]
        break
      }
      case `style`: {
        resultAttributes.style = parseColor(attributes[attributeKey])
        break
      }
      case `class`: {
        break
      }
      default: {
        resultAttributes[attributeKey] = attributes[attributeKey]
      }
    }
  }

  const { component: customComponent = null, attributes: customAttributes = {} } = replace(type, attributes) || {}

  return {
    type: customComponent || resultType,
    attributes: { ...resultAttributes, ...customAttributes },
  }
}

export default function Wysiwyg ({ json, replace = _noop, fields = {} }) {
  const { type, content = [], attributes = {} } = json

  const { type: Node, attributes: formatedAttributes } = getType(type, attributes, fields, replace)

  if (!Node) {
    return null
  }

  if (content.length <= 0) {
    return <Node {...formatedAttributes} data-testid={arguments[0][`data-testid`] || `wysiwyg`} />
  }

  return (
    <Node {...formatedAttributes} data-testid={arguments[0][`data-testid`] || `wysiwyg`}>
      {Array.isArray(content) &&
        content.map((contentJson, key) => {
          if (typeof contentJson === `string`) {
            return contentJson
          }

          return <Wysiwyg key={key} json={contentJson} replace={replace} fields={fields} />
        })}
    </Node>
  )
}

Wysiwyg.propTypes = {
  json: PropTypes.shape({
    type: PropTypes.string.isRequired,
    content: PropTypes.array,
    attributes: PropTypes.object,
  }).isRequired,
  replace: PropTypes.func,
  fields: PropTypes.object,
}
