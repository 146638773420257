import React, { memo } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import breakpoint from "styled-components-breakpoint"
import { Image } from "../images.component"
import { BANNER_TEMPLATES } from "../../modules/landing/banner.utils"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${breakpoint(`small`)`
    flex-direction: row;
  `}
`

const Row = styled.div`
  flex: ${props => props.rowSize};
  display: flex;
`

const RowImage = styled(Row)`
  align-items: stretch;
  flex: ${props => props.rowSize};
  display: ${props => (props.template === BANNER_TEMPLATES.RIGHT ? `none` : `flex`)};

  > div {
    max-height: 250px;
  }

  ${breakpoint(`small`)`
    > div {
      max-height: none;

      > div {
        padding-bottom: 0  !important;
        height: 100%;
        max-height: 100%;
      }
    }
  `}

  ${breakpoint(`medium`)`
    ${props =>
      props.template === BANNER_TEMPLATES.RIGHT &&
      `
        display: flex;
    `}
  `}
`

function RowsWithAdaptativeImages ({
  children,
  styleRow = {},
  imageProps = {},
  componentImage = Image,
  firstRowSize = 1,
  template = BANNER_TEMPLATES.CENTER,
}) {
  return (
    <Container>
      {React.Children.map(children, (child, i) => {
        if (!child) {
          return null
        }
        if (child.type === componentImage) {
          return (
            <RowImage key={i} rowSize={i === 0 ? firstRowSize : 1} template={template}>
              {React.cloneElement(child, {
                ...imageProps,
                style: {
                  flex: 1,
                  height: `100%`,
                  width: `auto`,
                  ...(imageProps.style || null),
                },
                objectFit: `contain`,
              })}
            </RowImage>
          )
        }

        return (
          <Row key={i} style={styleRow} rowSize={i === 0 ? firstRowSize : 1}>
            {child}
          </Row>
        )
      })}
    </Container>
  )
}

RowsWithAdaptativeImages.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
  styleRow: PropTypes.object,
  imageProps: PropTypes.object,
  componentImage: PropTypes.object,
  firstRowSize: PropTypes.number,
  template: PropTypes.string,
}

export default memo(RowsWithAdaptativeImages)
